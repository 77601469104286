import { Confidence, visitorIdentity } from "@spotify-confidence/sdk"

const confidence = Confidence.create({
  clientSecret: import.meta.env.VITE_CONFIDENCE_CLIENT_SECRET as string,
  environment: "client",
  region: "eu",
  timeout: 1000,
})

export default async function getSalespageCountdownFlagLoader(): Promise<string> {
  confidence.track(visitorIdentity())

  const flagValue = await confidence.getFlag(
    "filterly-web-salespage-countdown",
    {
      variant: "baseline",
    },
  )

  return flagValue.variant
}

