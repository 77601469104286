import getSubscriptionStatus from "@helpers/getSubscriptionStatus"
import { getUserCurrency } from "@helpers/sessionStorage"
import {
  getSession,
  listStripeCoupons,
  listStripePrices,
} from "@helpers/supabase"
import { defer, redirect } from "react-router-dom"
import { StripeCoupon, StripePrice } from "src/types"
import getSalespageCountdownFlagLoader from "./getSalespageFlagLoader"

export interface GetPricesLoaderResult {
  getPricesData: GetPricesData;
}

export interface GetPricesData {
  currency: string;
  introPriceCoupon: StripeCoupon | undefined;
  subscriptionStatus: "active" | "inactive" | "none";
  weeklyPrice: StripePrice | undefined;
  yearlyPrice: StripePrice | undefined;
  flagValue: string;
}

export default function getPricesLoader() {
  const getPricesPromise = getPrices()

  return defer({
    getPricesData: getPricesPromise,
  })
}

async function getPrices(): Promise<GetPricesData | Response> {
  const stripeCoupons = await listStripeCoupons()

  const stripePrices = await listStripePrices()

  const currency = await findCurrency({ stripePrices })

  const { data } = await getSession()

  const userId = data?.session?.user?.id

  if (!userId) {
    return redirect("/sign-up")
  }

  const flagValue = await getSalespageCountdownFlagLoader()

  const subscriptionStatus = await getSubscriptionStatus(userId)

  return {
    currency: currency,
    introPriceCoupon: findIntroPriceCoupon({ stripeCoupons }),
    subscriptionStatus: subscriptionStatus,
    weeklyPrice: findPrice({ interval: "week", stripePrices }),
    yearlyPrice: findPrice({ interval: "year", stripePrices }),
    flagValue: flagValue,
  }
}

async function findCurrency({
  stripePrices,
}: {
  stripePrices: StripePrice[];
}): Promise<string> {
  const userCurrency = await getUserCurrency()

  const stripePriceCurrencies = stripePrices.flatMap((price) => [
    price.currency,
    ...Object.keys(price?.currency_options),
  ])

  return stripePriceCurrencies.includes(userCurrency) ? userCurrency : "eur"
}

function findIntroPriceCoupon({
  stripeCoupons,
}: {
  stripeCoupons: StripeCoupon[];
}): StripeCoupon | undefined {
  return stripeCoupons.find((coupon) => coupon.id === "intro-90")
}

function findPrice({
  interval,
  stripePrices,
}: {
  interval: "week" | "year";
  stripePrices: StripePrice[];
}): StripePrice | undefined {
  return stripePrices.find((price) => price.recurring.interval === interval)
}
