
export default function getOnboardingFlagLoader() {
  // const flagValue = await getSalespageFlagLoader()

  // console.log("flagValue onboarding", flagValue)

  // switch (flagValue) {
  // case "v1":
  //   return redirect("/onboarding/v4")
  // case "v3":
  //   return redirect("/onboarding/v3")
  // default:
  //   return null
  // }

  return null
}
