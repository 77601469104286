import { Form } from "react-router-dom"
import { Button as HeadlessUiButton } from "@headlessui/react"
import { StripeCoupon, StripePrice } from "src/types"
import localizeCurrency from "@helpers/localizeCurrency"
import { useTranslation } from "react-i18next"

interface Props {
  buttonSubtitle: string;
  couponId: string | undefined;
  currency: string;
  isSubmitting: boolean;
  periodAmount: number;
  plan: "weekly" | "yearly";
  priceId: string | undefined;
  setIsSubmitting: (isSubmitting: boolean) => void;
  stripeIntroPriceCoupon: StripeCoupon | undefined;
  stripePrice: StripePrice | undefined;
  flagValue: string;
}

export default function SubscriptionFormTimer({
  buttonSubtitle,
  couponId,
  currency,
  isSubmitting,
  periodAmount,
  plan,
  priceId,
  setIsSubmitting,
  stripeIntroPriceCoupon,
  stripePrice,
  flagValue,
}: Props) {
  const handleClick = () => {
    setIsSubmitting(true)
  }
  const { t } = useTranslation()

  if (!stripePrice) return null

  const hasCurrencyOption =
    !!stripePrice.currency_options?.[currency]?.unit_amount_decimal

  const price = parseFloat(
    hasCurrencyOption
      ? stripePrice.currency_options[currency].unit_amount_decimal
      : stripePrice?.unit_amount_decimal,
  )

  const priceCurrency = hasCurrencyOption ? currency : stripePrice.currency

  if (!price) return null

  const pricePerPeriod = price / periodAmount

  const percentOff = stripeIntroPriceCoupon?.percent_off
  const introPrice = percentOff && price * (1 - percentOff / 100)
  const introPricePerPeriod = introPrice && introPrice / periodAmount

  const localizePrice = (price: number | undefined) => {
    if (!price) return ""

    return localizeCurrency({
      amountInCents: price,
      currency: priceCurrency,
    })
  }

  return (
    <Form onSubmit={handleClick} method="post" className="flex justify-center">
      <input type="hidden" name="couponId" value={couponId} />

      <input type="hidden" name="currency" value={currency} />

      <input type="hidden" name="plan" value={plan} />

      <input type="hidden" name="priceId" value={priceId} />

      <input type="hidden" name="flagValue" value={flagValue} />

      <HeadlessUiButton
        className="theme-button-primary-yellow"
        disabled={isSubmitting}
        type="submit"
      >
        {t("pages.checkout.plans.startNow", { price: localizePrice(percentOff ? introPricePerPeriod : pricePerPeriod) })}
        <p className="text-sm font-openSans italic text-black">{buttonSubtitle}</p>
      </HeadlessUiButton>
    </Form>
  )
}
