import Features from "@components/Features"
import FrequentlyAskedQuestions from "@components/FrequentlyAskedQuestions"
import LimitedTimeOfferDialog from "@components/LimitedTimeOfferDialog"
import Plans from "@components/Plans"
import SubscriptionForm from "@components/SubscriptionForm"
import SubscriptionFormTimer from "@components/SubscriptionFormTimer"
import ErrorPage from "@pages/ErrorPage"
import LoadingPage from "@pages/LoadingPage"
import { Suspense, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Await, useLoaderData } from "react-router-dom"
import {
  GetPricesData,
  GetPricesLoaderResult,
} from "src/loaders/getPricesLoader"

export default function PlansPage() {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const data = useLoaderData() as GetPricesLoaderResult
  const { t } = useTranslation()

  const [timeLeft, setTimeLeft] = useState("00:00")

  useEffect(() => {
    const startMinutes = 9
    setTimeLeft("09:00")

    const endTime = new Date().getTime() + startMinutes * 60 * 1000

    const timer = setInterval(() => {
      const now = new Date().getTime()
      const distance = endTime - now

      if (distance <= 0) {
        clearInterval(timer)
        setTimeLeft("00:00")
        return
      }

      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)

      setTimeLeft(`${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`)
    }, 1000)

    return () => clearInterval(timer)
  }, [data.getPricesData.flagValue])

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setIsDialogOpen(true)
  //   }, 30000)

  //   return () => clearTimeout(timeout)
  // }, [])

  if (isSubmitting) return <LoadingPage />

  return (
    <Suspense fallback={<LoadingPage />}>
      <Await errorElement={<ErrorPage />} resolve={data.getPricesData}>
        {(getPricesData: GetPricesData) => (
          <>
            <div className={`custom-container pb-${data.getPricesData.flagValue === "v1" ? "32" : "14"} pt-8`}>
              <h1 className="mb-14 break-words px-8 text-center font-openSansBold text-2xl">
                {t("pages.checkout.plans.heading1")}
              </h1>

              {getPricesData.flagValue === "v1" && timeLeft !== "00:00" && (
                <p className="mb-6 text-base font-semibold text-center">
                  <span className="bg-[#D65353] text-white rounded-lg px-4 py-2">
                    {t("pages.checkout.plans.offerEnds", { countdown: timeLeft })}
                  </span>
                </p>
              )}

              <section className="mb-16">
                <Plans
                  flagValue={getPricesData.flagValue}
                  currency={getPricesData.currency}
                  introPriceCoupon={
                    getPricesData.subscriptionStatus === "none"
                      ? getPricesData.introPriceCoupon
                      : undefined
                  }
                  isSubmitting={isSubmitting}
                  setIsSubmitting={setIsSubmitting}
                  weeklyPrice={getPricesData.weeklyPrice}
                  yearlyPrice={getPricesData.yearlyPrice}
                />
              </section>

              <section className="mb-12">
                <Features />
              </section>

              <section className="mb-24">
                <FrequentlyAskedQuestions />
              </section>

              <section className="space-y-4 text-center">
                <SubscriptionForm
                  buttonClassName="uppercase"
                  buttonTitle={
                    t("pages.checkout.plans.subscribeNow")
                  }
                  flagValue={getPricesData.flagValue}
                  couponId={
                    getPricesData.subscriptionStatus === "none"
                      ? getPricesData.introPriceCoupon?.id
                      : undefined
                  }
                  currency={getPricesData.currency}
                  isSubmitting={isSubmitting}
                  plan="weekly"
                  priceId={getPricesData.weeklyPrice?.id}
                  setIsSubmitting={setIsSubmitting}
                />

                <p className="font-openSansSemiBold">
                  {t("pages.checkout.plans.cancelAnytime")}
                </p>
              </section>
            </div>

            {getPricesData.flagValue === "v1" && (
              <div className="fixed bottom-0 left-0 right-0 p-4 bg-white/90 border-t border-gray-200">
                <SubscriptionFormTimer
                  buttonSubtitle={timeLeft === "00:00" ? "" : t("pages.checkout.plans.reservedFor", { countdown: timeLeft })}
                  couponId={
                    getPricesData.subscriptionStatus === "none"
                      ? getPricesData.introPriceCoupon?.id
                      : undefined
                  }
                  currency={getPricesData.currency}
                  flagValue={getPricesData.flagValue}
                  isSubmitting={isSubmitting}
                  periodAmount={1}
                  plan="weekly"
                  priceId={getPricesData.weeklyPrice?.id}
                  setIsSubmitting={setIsSubmitting}
                  stripeIntroPriceCoupon={getPricesData.introPriceCoupon}
                  stripePrice={getPricesData.weeklyPrice}
                />
              </div>
            )}

            <LimitedTimeOfferDialog
              currency={getPricesData.currency}
              flagValue={getPricesData.flagValue}
              isSubmitting={isSubmitting}
              onClose={() => setIsDialogOpen(false)}
              open={isDialogOpen}
              periodAmount={12}
              periodUnit="month"
              setIsSubmitting={setIsSubmitting}
              stripeIntroPriceCoupon={
                getPricesData.subscriptionStatus === "none"
                  ? getPricesData.introPriceCoupon
                  : undefined
              }
              stripePrice={getPricesData.yearlyPrice}
            />
          </>
        )}
      </Await>
    </Suspense>
  )
}
