import navigateToNextOnboardingPageAction from "@actions/navigateToNextOnboardingPageAction"
import { Navigate, Outlet } from "react-router-dom"
import GenderPage from "@pages/onboarding/GenderPage"
import TryFirstPage from "@pages/onboarding/TryFirstPage"
import BestLookPage from "@pages/onboarding/BestLookPage"
import CompanyPage from "@pages/onboarding/CompanyPage"

const v4OnboardingRoutes = [
  {
    element: <Outlet />,
    path: "/onboarding/v4",
    children: [
      {
        index: true,
        element: <Navigate to="gender" replace />,
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <GenderPage />,
        path: "gender",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <TryFirstPage />,
        path: "try-first",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <BestLookPage />,
        path: "best-look",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <CompanyPage />,
        path: "company",
      },
    ],
  },
]

export default v4OnboardingRoutes
