import Dialog from "@components/Dialog"
import SubscriptionForm from "@components/SubscriptionForm"
import localizeCurrency from "@helpers/localizeCurrency"
import { useTranslation } from "react-i18next"
import { StripeCoupon, StripePrice } from "src/types"

interface Props {
  currency: string;
  flagValue: string;
  isSubmitting: boolean;
  open: boolean;
  onClose: () => void;
  periodAmount: number;
  periodUnit: string;
  setIsSubmitting: (isSubmitting: boolean) => void;
  stripeIntroPriceCoupon: StripeCoupon | undefined;
  stripePrice: StripePrice | undefined;
}

export default function LimitedTimeOfferDialog({
  currency,
  flagValue,
  isSubmitting,
  onClose,
  open,
  periodAmount,
  periodUnit,
  setIsSubmitting,
  stripeIntroPriceCoupon,
  stripePrice,
}: Props) {
  const { t } = useTranslation()

  if (!stripeIntroPriceCoupon || !stripePrice) return null

  const hasCurrencyOption =
    !!stripePrice.currency_options?.[currency]?.unit_amount_decimal

  const price = parseFloat(
    hasCurrencyOption
      ? stripePrice.currency_options[currency].unit_amount_decimal
      : stripePrice?.unit_amount_decimal,
  )

  const priceCurrency = hasCurrencyOption ? currency : stripePrice.currency

  if (!price) return null

  const percentOff = stripeIntroPriceCoupon?.percent_off
  const introPrice = percentOff && price * (1 - percentOff / 100)

  const localizePrice = (price: number) =>
    localizeCurrency({ amountInCents: price, currency: priceCurrency })

  return (
    <Dialog onClose={onClose} open={open}>
      <div className="text-center font-openSans">
        <p className="mb-2 font-openSansSemiBold text-2xl">
          {t("pages.checkout.plans.dialog.limitedTimeOffer")}
        </p>

        <p className="font-openSansSemiBold text-4xl">{periodAmount}</p>

        <p className="text-lg mb-3 uppercase font-openSansSemiBold">
          {t(`pages.checkout.plans.${periodUnit}`, { count: periodAmount })}
        </p>

        {percentOff && (
          <p className="mb-2 line-through font-openSans">{localizePrice(price)}</p>
        )}

        <p className="mb-4 font-openSansSemiBold">
          {t("pages.checkout.plans.firstYearSpecialOffer", {
            price: localizePrice(percentOff ? introPrice : price),
          })}
        </p>

        <SubscriptionForm
          buttonClassName="theme-button-primary-black"
          buttonTitle={t("pages.checkout.plans.dialog.startNow")}
          couponId={stripeIntroPriceCoupon?.id}
          currency={priceCurrency}
          isSubmitting={isSubmitting}
          plan="yearly"
          priceId={stripePrice?.id}
          setIsSubmitting={setIsSubmitting}
          flagValue={flagValue}
        />

        <p className="mt-4 font-openSansSemiBold">
          {t("pages.checkout.plans.moneyBackGuarantee")}
        </p>
      </div>
    </Dialog>
  )
}
