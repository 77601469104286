import { createStripeSubscription, getUser } from "@helpers/supabase"
import { redirect } from "react-router-dom"

export default async function createStripeSubscriptionAction({
  request,
}: {
  request: Request;
}) {
  const { couponId, currency, plan, priceId, flagValue } = await getFormValues(request)

  if (!currency || !priceId) return redirect("/checkout/plans")

  const { data: userData } = await getUser()

  const email = userData?.user?.email

  if (!email) return redirect("/checkout/plans")

  const { data } = await createStripeSubscription({
    couponId,
    currency,
    email,
    priceId,
  })

  const { clientSecret, subscriptionId, invoiceSubtotal, invoiceCurrency } = data as {
    clientSecret: string;
    subscriptionId: string;
    invoiceSubtotal: number;
    invoiceCurrency: string;
  }

  const urlSearchParams = new URLSearchParams(request.url.split("?")[1])

  urlSearchParams.append("amount", invoiceSubtotal.toString())
  urlSearchParams.append("client_secret", clientSecret)
  urlSearchParams.append("currency", invoiceCurrency)
  urlSearchParams.append("plan", plan)
  urlSearchParams.append("subscription_id", subscriptionId)
  urlSearchParams.append("flag_value", flagValue)

  return redirect("/checkout/payment?" + urlSearchParams.toString())
}

async function getFormValues(request: Request): Promise<{
  couponId: string;
  currency: string;
  plan: "weekly" | "yearly";
  priceId: string;
  flagValue: string;
}> {
  const formData = await request.formData()

  const { couponId, currency, plan, priceId, flagValue } = Object.fromEntries(formData)

  return { couponId, currency, plan, priceId, flagValue } as {
    couponId: string;
    currency: string;
    plan: "weekly" | "yearly";
    priceId: string;
    flagValue: string;
  }
}
